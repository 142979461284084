const travel_plan = `Hello, I'm planning a vacation with my family next week and I'd like to know the train schedules. 
Could you please provide me with information about the available trains and their departure times?
I'm considering traveling to spain on either monday or tuesday.
Thank you for your help.`;
export default travel_plan;







