const phrases = `Ole hyvä ja tarkista tämä ja korjaa se,tästä jotta se ei toistu
Arvostan nopeaa huomiotasi tähän asiaan
On tärkeää korjata se mahdollisimman pian uusien ongelmien välttämiseksi
Kiitos nopeasta huomiostasi tähän asiaan
Ajattelen myös virallisen valituksen tekemistä tästä
Tee tarvittavat toimenpiteet, jotta tämä ei toistu
On tärkeää varmistaa, ettei näin käy kenellekään muulle
Molemmissa vaihtoehdoissa on hyviä ja ei niin hyviä asioita
Jokaisella vaihtoehdolla on omat etunsa ja haittansa
On tärkeää miettiä, mikä toimii parhaiten sinulle ja tavoitteillesi
Se oli erityinen hetki, jota tulen aina vaalimaan
Pidän sinut ajan tasalla kuinka asiat etenevät!
Olin täysin yllättynyt, suoraan sanottuna
On tärkeää ajatella sitä
Uskon, että voimme löytää tasapainon
Jotkut ihmiset pitävät tätä hyvänä ideana, ja jotkut eivät
On tärkeää puhua siitä
Kerron kuinka käy
Kiitos kuuntelemisesta!
Ystävällisin terveisin
Toivon että voit hyvin
Kiitos että kuuntelit
Tämän tilanteen valossa
terveisin`;
export default phrases;