const phrases = `ദയവായി ഇത് പരിശോധിച്ച് ഇത് പരിഹരിക്കുക, അങ്ങനെ ഇത് ആവർത്തിക്കില്ല
ഈ വിഷയത്തിൽ നിങ്ങളുടെ സത്വര ശ്രദ്ധയെ ഞാൻ അഭിനന്ദിക്കുന്നു
കൂടുതൽ പ്രശ്‌നങ്ങൾ ഉണ്ടാകാതിരിക്കാൻ അത് എത്രയും വേഗം നന്നാക്കേണ്ടത് പ്രധാനമാണ്
ഈ വിഷയത്തിൽ നിങ്ങളുടെ പെട്ടെന്നുള്ള ശ്രദ്ധയ്ക്ക് നന്ദി
ഇതേക്കുറിച്ച് ഔദ്യോഗികമായി പരാതി നൽകുന്നതിനെ കുറിച്ചും ആലോചിക്കുന്നുണ്ട്
ഇത് വീണ്ടും സംഭവിക്കാതിരിക്കാൻ ആവശ്യമായ നടപടികൾ സ്വീകരിക്കുക
ഇത് മറ്റാർക്കും സംഭവിക്കാതിരിക്കാൻ ശ്രദ്ധിക്കേണ്ടത് പ്രധാനമാണ്
രണ്ട് തിരഞ്ഞെടുപ്പുകൾക്കും നല്ലതും അല്ലാത്തതുമായ കാര്യങ്ങളുണ്ട്
ഓരോ ഓപ്ഷനും അതിന്റേതായ ഗുണങ്ങളും ദോഷങ്ങളുമുണ്ട്
നിങ്ങൾക്കും നിങ്ങളുടെ ലക്ഷ്യങ്ങൾക്കും ഏറ്റവും മികച്ചത് എന്താണെന്ന് ചിന്തിക്കേണ്ടത് പ്രധാനമാണ്
ഞാൻ എപ്പോഴും വിലമതിക്കുന്ന ഒരു പ്രത്യേക നിമിഷമായിരുന്നു അത്
കാര്യങ്ങൾ എങ്ങനെ പോകുന്നു എന്നതിനെക്കുറിച്ച് ഞാൻ നിങ്ങളെ അറിയിക്കും!
സത്യം പറഞ്ഞാൽ ഞാൻ ആകെ ആശ്ചര്യപ്പെട്ടു
അതിനെക്കുറിച്ച് ചിന്തിക്കേണ്ടത് പ്രധാനമാണ്
നമുക്ക് ഒരു ബാലൻസ് കണ്ടെത്താൻ കഴിയുമെന്ന് ഞാൻ കരുതുന്നു
ഇത് നല്ല ആശയമാണെന്ന് ചിലർ കരുതുന്നു, ചിലർ അങ്ങനെ ചെയ്യുന്നില്ല
അതിനെക്കുറിച്ച് സംസാരിക്കേണ്ടത് പ്രധാനമാണ്
അത് എങ്ങനെ പോകുന്നുവെന്ന് ഞാൻ നിങ്ങളെ അറിയിക്കും
കേട്ടതിനു നന്ദി!
ആത്മാർത്ഥതയോടെ
നിങ്ങൾ സുഖമായിരിക്കുന്നുവെന്ന് ഞാൻ പ്രതീക്ഷിക്കുന്നു
ശ്രവിച്ചതിനു നന്ദി
ഈ സാഹചര്യത്തിന്റെ വെളിച്ചത്തിൽ
സൂക്ഷിക്കുക`;
export default phrases;