const weather = `Oh, the weather has been quite nice lately.
We've had some sunny days with a gentle breeze. 
It's been perfect for taking walks and enjoying the outdoors.
How's the weather been on your end?`;
export default weather;







