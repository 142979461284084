const bathroom = `ബാത്ത്റൂം റിപ്പയർ അഭ്യർത്ഥന
ഹായ്,
നിങ്ങൾ സുഖമായിരിക്കുന്നുവെന്ന് ഞാൻ പ്രതീക്ഷിക്കുന്നു.
എന്റെ അപ്പാർട്ട്മെന്റിലെ ബാത്ത്റൂമിനെക്കുറിച്ച് എനിക്ക് നിങ്ങളോട് പറയണം.
ടാപ്പുകൾ ചോർന്നൊലിക്കുന്നു, ചില ടൈലുകൾ തകർന്നിരിക്കുന്നു, പൂപ്പൽ ഉണ്ട്, ഇത് ഉപയോഗിക്കാൻ ബുദ്ധിമുട്ടാണ്.
ഇത് അപ്പാർട്ട്മെന്റിൽ ദുർഗന്ധം വമിക്കുകയും ചെയ്യുന്നു.
ബിൽഡിംഗ് കമ്പനിക്ക് ബാത്ത്റൂം ശരിയാക്കാൻ കഴിയുമോ?
ഈ പ്രശ്നങ്ങൾ അവസാനിപ്പിച്ച് അപ്പാർട്ട്മെന്റ് എല്ലാവർക്കും മികച്ചതാക്കേണ്ടത് പ്രധാനമാണ്.
നവംബർ 15-ന് ആരംഭിച്ച് ഡിസംബർ 10-നകം പണി പൂർത്തിയാക്കാം.
നിങ്ങൾക്ക് കൂടുതൽ വിവരങ്ങൾ വേണമെങ്കിൽ അല്ലെങ്കിൽ ഇതിനെക്കുറിച്ച് സംസാരിക്കണമെങ്കിൽ, നിങ്ങൾക്ക് എന്നെ 555-555-5555 എന്ന നമ്പറിൽ വിളിക്കാം അല്ലെങ്കിൽ example@email.com എന്ന വിലാസത്തിൽ എനിക്ക് ഇമെയിൽ ചെയ്യാം.
ശ്രവിച്ചതിനു നന്ദി.
സൂക്ഷിക്കുക,`
export default bathroom;