const weather = `Oho, sää on ollut viime aikoina tosi kiva.
Meillä on ollut aurinkoisia päiviä lempeällä tuulella.
Se on ollut täydellinen kävelyyn ja ulkona nauttimiseen.
Millainen sää on sujunut?`;
export default weather;







