const washingmachine = `വിഷയം: വാഷിംഗ് മെഷീന് അടിയന്തര റിപ്പയർ അഭ്യർത്ഥന
പ്രിയ പ്രോപ്പർട്ടി മാനേജർ
എന്റെ അപ്പാർട്ട്മെന്റിലെ വാഷിംഗ് മെഷീനിൽ ഒരു പ്രശ്നം റിപ്പോർട്ട് ചെയ്യാൻ ഞാൻ എഴുതുകയാണ്.
യന്ത്രം വെള്ളം ചോർത്തുന്നതും വാഷ് സൈക്കിൾ ശരിയായി പൂർത്തിയാക്കാത്തതും ഏറെ ബുദ്ധിമുട്ടുണ്ടാക്കുന്നുണ്ട്.
വെള്ളം പെട്ടെന്ന് പരിഹരിച്ചില്ലെങ്കിൽ അപ്പാർട്ട്മെന്റിന് കേടുപാടുകൾ സംഭവിക്കുമെന്ന് ഞാൻ ഭയപ്പെടുന്നു.
കൂടുതൽ പ്രശ്‌നങ്ങൾ ഉണ്ടാകാതിരിക്കാൻ അത് എത്രയും വേഗം നന്നാക്കേണ്ടത് പ്രധാനമാണ്.
എന്റെ കയ്യിൽ അലക്കാനുള്ള ഒരു കൂമ്പാരം ഉണ്ട്.
നിങ്ങൾക്ക് കഴിയുന്നതും വേഗം വാഷിംഗ് മെഷീൻ ശരിയാക്കാൻ ആരെയെങ്കിലും അയയ്ക്കുക.
ഈ വിഷയത്തിൽ നിങ്ങളുടെ സത്വര ശ്രദ്ധയെ ഞാൻ അഭിനന്ദിക്കുന്നു.
നന്ദി.
ആത്മാർത്ഥതയോടെ,`;

export default washingmachine;