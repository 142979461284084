const restuarant = `വിഷയം: എന്റെ മോശം റെസ്റ്റോറന്റ് സന്ദർശനത്തെക്കുറിച്ചുള്ള പരാതി
പ്രിയ മാനേജർ,
നിങ്ങളുടെ റെസ്റ്റോറന്റിൽ എനിക്കുണ്ടായ മോശം അനുഭവത്തെക്കുറിച്ച് ഞാൻ നിങ്ങളോട് പറയാൻ ആഗ്രഹിക്കുന്നു.
ഞാൻ ഇന്നലെ അവിടെ ഭക്ഷണം കഴിച്ചപ്പോൾ, എന്റെ ഭക്ഷണത്തിൽ ഒരു മുടി കണ്ടെത്തി, അത് ശരിക്കും മൊത്തത്തിലുള്ളതായിരുന്നു.
കൂടാതെ, ഞാൻ അവരോട് ഇക്കാര്യം പറഞ്ഞപ്പോൾ വെയിറ്റർ നല്ലവനല്ല. അവർ പരുഷമായി പെരുമാറി, അത് കാര്യമാക്കാൻ തോന്നിയില്ല.
നിങ്ങളുടെ റെസ്റ്റോറന്റ് വൃത്തിയുള്ളതാണെന്നും നിങ്ങളുടെ ജീവനക്കാർ ഉപഭോക്താക്കളോട് മര്യാദയുള്ളവരാണെന്നും ഉറപ്പാക്കുക.
ഇത് മറ്റാർക്കും സംഭവിക്കാതിരിക്കാൻ ശ്രദ്ധിക്കേണ്ടത് പ്രധാനമാണ്.
ഒരു ഔദ്യോഗിക ക്ഷമാപണവും പണവും തിരികെ ലഭിക്കാൻ ഞാൻ ആഗ്രഹിക്കുന്നു.
ഇതേക്കുറിച്ച് ഔദ്യോഗികമായി പരാതി നൽകാനും ആലോചിക്കുന്നുണ്ട്.
ഇത് വീണ്ടും സംഭവിക്കാതിരിക്കാൻ ആവശ്യമായ നടപടികൾ സ്വീകരിക്കുക`;

export default restuarant;