const bad_food = `വിഷയം: ഞാൻ വാങ്ങിയ ചിക്കൻ നഗറ്റിലെ പ്രശ്നം
പ്രിയ സ്റ്റോർ മാനേജർ,
ഞാൻ ഇന്ന് നിങ്ങളുടെ കടയിൽ നിന്ന് ചിക്കൻ നഗറ്റ് വാങ്ങിയപ്പോൾ സംഭവിച്ച ഒരു മോശം കാര്യത്തെക്കുറിച്ച് നിങ്ങളോട് പറയാൻ ആഗ്രഹിക്കുന്നു.
ഞാൻ അത്താഴത്തിന് പാകം ചെയ്തപ്പോൾ, അവയ്ക്ക് മണമില്ലായിരുന്നു, വിചിത്രമായി തോന്നി.
അടുത്ത് നോക്കിയപ്പോൾ നിർദ്ദേശങ്ങൾ പാലിച്ചിട്ടും ചില ഭാഗങ്ങൾ പാകമായിട്ടില്ല.
ഇത് പാകം ചെയ്യാത്ത ഭക്ഷണം കഴിക്കുന്നതിനാൽ അസുഖം വരുമോ എന്നതിൽ എനിക്ക് ശരിക്കും സങ്കടവും ആശങ്കയും തോന്നി.
ഞാൻ സാധാരണയായി നിങ്ങളുടെ സ്റ്റോറിൽ വിശ്വസിക്കുന്നു, എന്നാൽ നിങ്ങളുടെ ഭക്ഷണം എത്രത്തോളം സുരക്ഷിതമാണെന്ന് ഇപ്പോൾ എനിക്ക് അത്ര ഉറപ്പില്ല.
ദയവായി ഇത് പരിശോധിച്ച് ഇത് പരിഹരിക്കുക, അങ്ങനെ ഇത് ആവർത്തിക്കില്ല.
മോശം ചിക്കൻ നഗറ്റുകൾക്ക് എന്റെ പണം തിരികെ നൽകാൻ ഞാൻ ആഗ്രഹിക്കുന്നു.
ഇത് ആവർത്തിക്കാതിരിക്കാൻ നിങ്ങൾ എങ്ങനെ ഭക്ഷണം ഉണ്ടാക്കുന്നുവെന്നും പരിശോധിക്കണം.
നിങ്ങൾക്ക് ഇതിനെക്കുറിച്ച് എന്തെങ്കിലും ചെയ്യാനും നിങ്ങളുടെ സ്റ്റോറിൽ എന്നെ വീണ്ടും വിശ്വസിക്കാനും കഴിയുമെന്ന് ഞാൻ പ്രതീക്ഷിക്കുന്നു.
ആത്മാർത്ഥതയോടെ,`;
export default bad_food;
