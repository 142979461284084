const travel_plan = `Hei, suunnittelen lomaa perheeni kanssa ensi viikolla ja haluaisin tietää juna-aikataulut.
Voisitko ystävällisesti antaa minulle tietoja saatavilla olevista junista ja niiden lähtöajoista?
Harkitsen matkustamista espanjaan joko maanantaina tai tiistaina.
Kiitos avustasi.`;
export default travel_plan;







